/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var ball = document.getElementById("ball");

        document.onmousemove = function (e) {
          var x = e.clientX;
          var y = e.clientY;

          // Add a delay of 100 milliseconds
          setTimeout(function () {
            ball.style.left = x + "px";
            ball.style.top = y + "px";
          }, 90);
        };


        // STICKY HEADER

        const header = document.querySelector(".site-header");
        const toggleClass = "is-sticky";

        function handleScroll() {
          const currentScroll = window.pageYOffset;
          if (currentScroll > 150) {
            header.classList.add(toggleClass);
          } else {
            header.classList.remove(toggleClass);
          }
        }
        
        window.addEventListener("scroll", handleScroll);





      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Home page
    'studio': {
      init: function() {
        // JavaScript to be fired on the home page

        $('.owl-carousel-studio').owlCarousel({
          center: true,
          items:2,
          loop:true,
          margin:10,
          dots:false,
          autoplay:true,
          autoplayTimeout:3500,
          responsive:{
              600:{
                  items:2
              }
          }
        });

        $('.collapse').on('shown.bs.collapse', function() {
          $(this).parent().find(".glyphicon-plus").removeClass("glyphicon-plus").addClass("glyphicon-minus");
        }).on('hidden.bs.collapse', function() {
          $(this).parent().find(".glyphicon-minus").removeClass("glyphicon-minus").addClass("glyphicon-plus");
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Home page
    'single_proyectos': {
      init: function() {
        // JavaScript to be fired on the home page

        $('.owl-carousel-single').owlCarousel({
          items:1,
          loop:true,
          margin:10,
          nav: true,
          dots:true,
          responsive:{
              600:{
                  items:1
              }
          }
        });

        $('.collapse').on('shown.bs.collapse', function() {
          $(this).parent().find(".glyphicon-plus").removeClass("glyphicon-plus").addClass("glyphicon-minus");
        }).on('hidden.bs.collapse', function() {
          $(this).parent().find(".glyphicon-minus").removeClass("glyphicon-minus").addClass("glyphicon-plus");
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
